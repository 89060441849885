export const STATES = {
    "Al Beheira": [ // state
        "Al Beheira", //city
        "Etay Al Barud",
        "Wadi Al Natroun",
        "Hosh Issa",
        "Kom Hamadah",
        "Damanhour",
        "Kafr El Dawwar",
        "Abou Al Matamer",
        "Edko",
        "Shubrakhit",
        "Rashid",
        "Edfina",
        "Al Delengat",
        "Al Rahmaniyah",
        "Al Mahmoudiyah",
        "El Nubariyah",
        "Abu Hummus"
    ],
    "Al Daqahliya": [
        "Al Mansoura",
        "Manzala",
        "Aga",
        "Met Salsel",
        "Tamai El Amadid",
        "Bani Ebed",
        "Nabroo",
        "Belqas",
        "Meet Ghamr",
        "Al Daqahliya",
        "Dekernes",
        "Shrbeen",
        "Mahalet Demna",
        "El Gamalia",
        "El Sinblaween",
        "El Mataria",
        "Talkha",
        "Menit El Nasr"
    ],
    "Al Fayoum": [
        "Manshaa Abdalla",
        "Tameaa",
        "Youssef Sadek",
        "El Aagamen",
        "Al Fayoum",
        "Sonores",
        "Sersenaa",
        "New Fayoum",
        "Ebshoy",
        "Sanhoor",
        "Atsa",
        "Kofooer Elniel",
        "Manshaa Elgamal"
    ],
    "Al Gharbia": [
        "Basyoon",
        "Qotoor",
        "Al Gharbia",
        "Alsanta",
        "Kafr Alziat",
        "Zefta",
        "Tanta",
        "Samanood",
        "Al Mahala Al Kobra"
    ],
    "Al Meniya": [
        "Minya",
        "Mghagha",
        "Bani Mazar",
        "Malawi",
        "Dermwas",
        "Abo Korkas",
        "Eladwa",
        "Matai",
        "Samaloot"
    ],
    "Al Monufia": [
        "Shebin El Koom",
        "Al Monufia",
        "Quesna",
        "Berket Al Sabei",
        "Shohada",
        "Ashmoon",
        "Menoof",
        "Sadat City",
        "Tala"
    ],
    "Al Sharqia": [
        "Al Qareen",
        "Belbes",
        "Al Hasiniya",
        "Al Sharqia",
        "Qanayiat",
        "Hehya",
        "Al Ibrahimiya",
        "10th of Ramdan City",
        "Abu Hammad",
        "Zakazik",
        "Darb Negm",
        "Awlad Saqr",
        "Mashtool Al Sooq",
        "Meniya Alqamh",
        "Abu Kbeer",
        "Inshas",
        "Kafr Saqr",
        "San Al Hagar",
        "Faqous",
        "Al Salhiya Al Gedida"
    ],
    "Alexandria": [
        "Maamora",
        "Smouha",
        "City Center",
        "Roshdy",
        "Luran",
        "Kafer Abdou",
        "Miami",
        "Sporting",
        "Al Bitash",
        "Manshia",
        "Borg El Arab",
        "Zezenya",
        "Abees",
        "San Stefano",
        "Al A'mriah",
         "Al Soyof",
        "Azarita",
        "Glem",
        "Muntazah",
        "Awaied-Ras Souda",
        "Khorshid",
        "Abu Keer",
        "Sedi Bisher",
        "Asafra",
        "Alexandria",
        "Sedi Gaber",
        "El-Agamy",
        "Bangar EL Sokar",
        "Mandara",
        "Stanly",
        "Al Nahda Al Amria",
        "Mahtet El-Raml",
        "Sedi Kreir",
        "El Borg El Kadem"
    ],
    "Aswan": [
        "Draw",
        "Nasr Elnoba",
        "El Klabsha",
        "Aswan",
        "Edfo",
        "Kom Ombo",
        "Al Sad Al Aali",
        "Markaz Naser",
        "El Redisia",
        "Abu Simbel",
        "El Sbaaia"
    ],
    "Asyut": [
        "Elfath",
        "Manqbad",
        "El Badari",
        "El Ghnayem",
        "Manflout",
        "Abnoub",
        "Dayrout",
        "Sahel Selim",
        "Dronka",
        "Serfa",
        "Assuit Elgdeda",
        "Asyut",
        "Abou Teag",
        "El Qusya",
        "Beny Hossien"
    ],
    "Bani Souaif": [
        "Bani Souaif",
        "El Wastaa",
        "Smostaa",
        "El Fashn",
        "Bebaa",
        "New Bani Souaif",
        "Naser",
        "El Korimat",
        "Ahnaseaa"
    ],
    "Cairo": [
        "Al Kasr Al Einy",
        "Manial Al Rodah",
        "1st Settlement",
        "Down Town",
        "Ramsis",
        "Al Rehab",
        "Ezbt Elhagana",
        "Mansheyet Naser",
        "15th of May City",
        "Gesr Al Suez",
        "3rd Settlement",
        "Al Moski",
        "New Maadi",
        "Al Salam City",
        "Fustat",
        "Al Matareya",
        "Ain Shams",
        "Cairo",
        "Abdeen",
        "Abdo Basha",
        "Basateen",
        "Ezbet El Nakhl",
        "5th Settlement",
        "Nasr City",
        "Amiria",
        "Helwan",
        "New Cairo",
        "Al Zeitoun",
        "Madinty",
        "El Shorouk",
        "Katamiah",
        "El Tahrir",
        "Rod El Farag",
        "Sayeda Zeinab",
        "Almaza",
        "Hadayek Helwan",
        "El Meraag",
        "Helmeya",
        "Heliopolis",
        "El Herafieen",
        "Al Azhar",
        "Hadayek Maadi",
        "Masaken Sheraton",
        "Helmiet Elzaitoun",
        "Mokattam",
        "Garden City",
        "Maadi",
        "Dar Al Salam",
        "Abaseya",
        "Mirage City",
        "Ghamrah",
        "New El Marg",
        "Badr City",
        "Zamalek",
        "Hadayek Al Qobah",
        "Shubra",
        "Cornish Al Nile",
        "EL Marg",
        "New Nozha",
        "Maadi Degla",
        "Al Daher",
        "Misr El Kadima",
        "Al Kalaa"
    ],
    "Damietta": [
        "Fareskor",
        "Kafr Saad",
        "Meet Abughaleb",
        "Kafr Bateekh",
        "Al Zarkah",
        "Al Sarw",
        "Ezbet El Borg",
        "Al Rodah",
        "Damietta",
        "New Damietta",
        "Ras El Bar"
    ],
    "Giza": [
        "Abu Al Nomros",
        "Qism el Giza",
        "Kit Kat",
        "Al Moatamadia",
        "Mansoureya",
        "Agouza",
        "Saft El Laban",
        "Al Monib",
        "Shabramant",
        "Dokki",
        "Nahai Elbalad",
        "Al Wahat",
        "Abou Rawash",
        "Al Saf",
        "Faisal",
        "Beherms",
        "Imbaba",
        "Bolak Al Dakrour",
        "Saqara",
        "Al Nobariah",
        "Harania",
        "Smart Village",
        "Berak Alkiaam",
        "Saqeel",
        "Hawamdya",
        "Al Manashi",
        "Aossim",
        "Haram",
        "Warraq",
        "Tirsa",
        "Kerdasa",
        "Manial",
        "Sheikh Zayed",
        "6th of October",
        "Hadayeq El Ahram",
        "Manial Shiha",
        "Sakiat Mekki",
        "Omraneya",
        "Mohandessin",
        "Al Barageel",
        "Al Kom Al Ahmer",
        "Giza",
        "Badrashin"
    ],
    "Ismailia": [
        "Abo Sultan",
        "Fayed",
        "Abu Swer",
        "Qantara Sharq",
        "Ismailia",
        "Al Kasaseen",
        "Elsalhia Elgdida",
        "Qantara Gharb",
        "Nfeesha",
        "El Tal El Kebir",
        "Srabioom"
    ],
    "Kafr El Sheikh": [
        "Borollos",
        "Metobas",
        "Balteem",
        "Qeleen",
        "Fooh",
        "Seedy Salem",
        "Hamool",
        "Kafr El Sheikh",
        "Desouq",
        "Bela",
        "Al Riadh"
    ],
    "Luxor": [
        "El Tood",
        "El Korna Elgdida",
        "Armant Gharb",
        "El Baiadiaa",
        "El Korna",
        "El Karnak",
        "Esnaa",
        "Armant Sharq",
        "El Boghdady",
        "Luxor"
    ],
    "Matrooh": [
        "Matrooh",
        "Sidi Abdel Rahman",
        "El Dabaa",
        "El Alamein",
        "Marsa Matrooh"
    ],
    "New Valley": [
        "El Kharga",
        "New Valley"
    ],
    "North Sinai": [
        "Al Arish"
    ],
    "Port Said": [
        "Zohoor District",
        "South District",
        "Port Said",
        "Port Fouad"
    ],
    "Qalyubia": [
        "Sheben Alkanater",
        "Al Shareaa Al Gadid",
        "El Oboor",
        "Tookh",
        "Kafr Shokr",
        "Izbet Al Nakhl",
        "Om Bayoumi",
        "Banha",
        "Bahteem",
        "Meet Nama",
        "Abu Zaabal",
        "El Khsos",
        "Shoubra Alkhema",
        "Al Khanka",
        "El Kanater EL Khayrya",
        "Orabi",
        "Mostorod",
        "Qaha",
        "Qalyubia",
        "Qalyoob",
        "El Qalag"
    ],
    "Qena": [
        "Abu Tesht",
        "Qeft",
        "Naqada",
        "Qena",
        "Al Waqf",
        "Deshna",
        "Qoos",
        "Naga Hamadi",
        "Farshoot"
    ],
    "Red Sea": [
        "Safaga",
        "Ras Ghareb",
        "Gouna",
        "Marsa Alam",
        "Qouseir",
        "Hurghada"
    ],
    "Sohag": [
        "Tahta",
        "El Kawthar",
        "Bardes",
        "Tema",
        "Gerga",
        "Saqatlah",
        "Eljazeera",
        "Dar Elsalam",
        "Maragha",
        "El Monshah",
        "Sohag",
        "Elbalyna",
        "Akhmem",
        "Ghena"
    ],
    "South Sinai": [
        "Saint Catherine",
        "Taba",
        "Dahab",
        "Neweibaa",
        "Sharm Al Sheikh",
        "Toor Sinai"
    ],
    "Suez": [
        "Ataka District",
        "Suez",
        "Al Adabya",
        "Elganaien District",
        "El Arbeen District",
        "Al Suez",
        "Ain Al Sukhna"
    ]
}